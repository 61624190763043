import Axios, { AxiosResponse } from 'axios';
import QueryString from 'qs';
import { buildHeaders, buildUrl } from '../FraytRequest';
import { isObject } from '../Utility';
import { Shipper } from './ShipperAction';
import { Address } from './UserAction';
import { Accessorial } from './AccessorialAction';
import { PublicMatch } from './MatchStopAction';

// our phoenix server returns match without an explicit preferred_driver_id
export type MatchResponse = AxiosResponse<{
  response: Omit<Match, 'preferred_driver_id'>;
}>;
export type MatchResponseData = {
  response: Omit<Match, 'preferred_driver_id'>;
};

export async function duplicateMatch(
  matchId: string,
  data: MatchData
): Promise<MatchResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`matches/${matchId}/duplicate`);

  return Axios.post(url, data, { headers });
}

export async function cancelMatch(
  matchId: string,
  reason: string,
  message: string
): Promise<MatchResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`matches/${matchId}`);

  return Axios.delete(url, { data: { reason, message }, headers });
}

export async function createMatch(data: NewMatchData): Promise<MatchResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`matches`);

  return Axios.post(url, data, { headers });
}

export async function updateMatch(
  matchId: string,
  data:
    | MatchData
    | MatchRatingData
    | PreferredDriverMatchData
    | { state: 'authorized' }
): Promise<MatchResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`matches/${matchId}`);

  // The high timeout here is to allow for the expensive optimization queries
  return Axios.put(url, data, { headers, timeout: 120_000 });
}

export async function authorizeMatch(matchId: string): Promise<MatchResponse> {
  return updateMatch(matchId, { state: 'authorized' });
}

export async function getMatch(matchId: string): Promise<MatchResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`matches/${matchId}`);

  return Axios.get(url, { headers });
}

export type MatchesResponse = AxiosResponse<MatchesResponseData>;

export type MatchesResponseData = {
  response: Omit<Match, 'preferred_driver_id'>[];
  page_count: number;
};

export async function getMatches(
  filters: MatchFilters
): Promise<MatchesResponse> {
  const params = QueryString.stringify(filters);
  const headers = buildHeaders();
  const url = buildUrl(`matches?${params}`);

  return Axios.get(url, { headers });
}

export type MatchFilters = {
  states?: string;
  search?: string;
  location_id?: string;
  shipper_id?: string;
  page: number;
  per_page: number;
};

export type VehicleType = {
  id: string;
  name: string;
  legacy_id: number;
  index: number;
  key: string;
};

export type VehicleClass = {
  id: string;
  name: string;
  index: number;
  type: VehicleType;
};

export type MatchContract = {
  allowed_cancellation_states: MatchState[];
  id: string;
  name: string;
  contract_key: string;
  company_id: string;
};

export type Match = PublicMatch & {
  shipper: Shipper | null;
  coupon: null | Coupon;
  total_distance: number;
  total_weight: number;
  total_volume: number;
  total_price: number;
  price_discount: number;
  stops: MatchStop[];
  fees: Fee[];
  accessorials: Accessorial[];
  eta: ETA | null;
  dropoff_at: string | null;
  pickup_at: string | null;
  scheduled: boolean;
  schedule_id: string | null;
  pickup_notes: string | null;
  route_identifier: string | null;
  identifier: string | null;
  sender: Contact | null;
  self_sender: boolean;
  cancel_reason: string | null;
  contract: MatchContract;
  bill_of_lading_photo: string | null;
  origin_photo: string | null;
  driver: Driver | null;
  rating: number | null;
  rating_reason: string | null;
  unload_method: UnloadMethod | null;
  optimized_stops: boolean;
  timezone: string | null;
  origin_photo_required: boolean;
  bill_of_lading_required: boolean;
  preferred_driver: Driver | undefined;
  platform: 'marketplace' | 'deliver_pro' | 'preferred_driver' | 'auto_assign';
  signature_photo: string | null;
  polyline: [number, number][];
};

export function isMatch(match: unknown) {
  return isObject(match, ['id', 'stops']);
}

export type MatchData = {
  coupon_code?: string | null;
  optimize?: boolean;
  origin_address?: string | null;
  origin_place_id?: string | null;
  sender?: ContactData | null;
  stops?: MatchStopData[];
  accessorials?: MatchAccessorialData[];
  contract_id?: string | null;
  form_step?: string | null;
  vehicle_class_id?: string | null;
  preferred_driver_id?: string | undefined | null;
} & PickOptional<
  Match,
  | 'po'
  | 'route_identifier'
  | 'scheduled'
  | 'pickup_at'
  | 'dropoff_at'
  | 'unload_method'
  | 'pickup_notes'
  | 'self_sender'
  | 'bill_of_lading_required'
  | 'origin_photo_required'
  | 'platform'
  | 'signature_photo'
>;

export type NewMatchData = {
  stops?: NewMatchStopData[];
} & MatchData;

export type MatchAccessorialData = {
  key: string;
};

export type MatchRatingData = PickOptional<Match, 'rating' | 'rating_reason'>;
export type PreferredDriverMatchData = PickOptional<
  MatchData,
  'platform' | 'preferred_driver_id'
>;

export type MatchStop = {
  eta: ETA | null;
  state: MatchStopState;
  index: number;
  recipient: Contact | null;
  self_recipient: boolean;
  delivery_notes: string | null;
  destination_address: Address;
  signature_photo: string | null;
  signature_name: string | null;
  signature_type: SignatureType;
  referrer_type: ReferrerType;
  signature_instructions: string | null;
  destination_photo: string | null;
  destination_photo_required: boolean;
  has_load_fee: boolean;
  needs_pallet_jack: boolean;
  driver_tip: number;
  id: string;
  identifier: string | null;
  dropoff_by: string | null;
  items: MatchStopItem[];
  state_transition: StateTransition<MatchStopState>;
  signature_required: boolean;
  po: string | null;
  electronic_bill_of_lading_photo: string | null;
  tracking_url: string | null;
  delivered_at: string | null;
};

export type MatchSLA = {
  type: 'pickup' | 'delivery';
  start_time: string;
  end_time: string;
  completed_at: string;
};

export type MatchStopData = {
  destination_address?: string | null;
  destination_place_id?: string | null;
  items?: MatchStopItemData[];
  recipient?: ContactData | null;
} & PickOptional<
  MatchStop,
  | 'id'
  | 'dropoff_by'
  | 'has_load_fee'
  | 'needs_pallet_jack'
  | 'index'
  | 'po'
  | 'delivery_notes'
  | 'self_recipient'
  | 'signature_required'
  | 'signature_type'
  | 'signature_instructions'
  | 'destination_photo_required'
  | 'electronic_bill_of_lading_photo'
>;

export type NewMatchStopData = {
  items?: NewMatchStopItemData[];
} & Omit<MatchStopData, 'id'>;

export type MatchStopItem = {
  id: string;
  barcode_readings: BarcodeReading[];
} & Required<MatchStopItemData>;

export type MatchStopItemData = {
  id?: string;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  volume?: number | null;
  pieces?: number;
  weight?: number;
  description?: string | null;
  type?: MatchStopItemType;
  barcode?: string | null;
  barcode_delivery_required?: boolean;
  barcode_pickup_required?: boolean;
  declared_value?: number | null;
};

export type NewMatchStopItemData = Omit<MatchStopItemData, 'id'>;

export type BarcodeReading = {
  type: BarcodeReadingType;
  state: BarcodeReadingState;
  photo: string | null;
  barcode: string | null;
  inserted_at: string | null;
  item_id: string;
};

export type StateTransition<S> = {
  notes: string | null;
  updated_at: string | null;
  from: S;
  to: S;
};

export type Coupon = {
  percentage: number;
  code: string;
};

export type Contact = {
  id: string;
} & Required<ContactData>;

export type ContactData = {
  name: string;
  email?: string | null;
  notify: boolean;
  phone_number?: string | null;
};

export type Fee = {
  id: string;
  amount: number;
  original_amount?: number;
  description: string | null;
  type: string;
  name: string;
};

export type Driver = {
  id: string;
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  profile_image: string | null;
  current_location: DriverLocation | null;
  vehicle: Vehicle;
  completed_matches: number;
  platforms: Array<'marketplace' | 'deliver_pro'>;
};

export type DriverLocation = {
  id: string;
  lat: number;
  lng: number;
  created_at: string;
};

export type DriverVehicleType = Omit<VehicleType, 'classes'>;

export type Vehicle = {
  id: string;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_year: number;
  vehicle_class: VehicleClass;
};

export type ETA = {
  id: string;
  arrive_at: string | null;
  distance: number | null;
  window_start: string | null;
  window_end: string | null;
};

export enum UnloadMethod {
  DockToDock = 'dock_to_dock',
  LiftGate = 'lift_gate',
}

export enum MatchState {
  DriverCanceled = 'driver_canceled',
  AdminCanceled = 'admin_canceled',
  Canceled = 'canceled',
  Pending = 'pending',
  Inactive = 'inactive',
  Scheduled = 'scheduled',
  AssigningDriver = 'assigning_driver',
  Offered = 'offered',
  OfferNotAccepted = 'offer_not_accepted',
  Accepted = 'accepted',
  EnRouteToPickup = 'en_route_to_pickup',
  ArrivedAtPickup = 'arrived_at_pickup',
  EnRouteToReturn = 'en_route_to_return',
  ArrivedAtReturn = 'arrived_at_return',
  PickedUp = 'picked_up',
  Completed = 'completed',
  Charged = 'charged',
  UnableToPickup = 'unable_to_pickup',
}

export enum MatchStopState {
  Unserved = 'unserved',
  Undeliverable = 'undeliverable',
  Pending = 'pending',
  EnRoute = 'en_route',
  Arrived = 'arrived',
  Signed = 'signed',
  Delivered = 'delivered',
  Returned = 'returned',
}

export enum MatchStopItemType {
  Item = 'item',
  Pallet = 'pallet',
}

export enum BarcodeReadingType {
  Pickup = 'pickup',
  Delivery = 'delivery',
}

export enum BarcodeReadingState {
  Captured = 'captured',
  Missing = 'missing',
}

export enum SignatureType {
  Electronic = 'electronic',
  Photo = 'photo',
  ElectronicBillOfLading = 'electronic_bill_of_lading',
}

export enum ReferrerType {
  Select_Referrer = 'select_referrer',
  None_Other = 'none/other',
  LinkedIn = 'linkedin',
  Google = 'google',
  Advertising = 'advertising',
  PR = 'pr',
  Search_Engine = 'search_engine',
  Email = 'email',
  Friend_Colleague = 'friend_colleague',
  Driver = 'driver',
  Billboard = 'billboard',
}
